import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"

function onSubmit(token) {
  document.getElementById("contact").submit()
}
const thankyousomuch = () => (
  <Layout>
    <SEO
      title="WEST – Thank you"
      description="Learn more about our upcoming WEST program."
    ></SEO>
    <Helmet>
      {/* <script src="https://www.google.com/recaptcha/api.js"></script> */}
    </Helmet>

    <div
      class="maxWidth pagePadding"
      style={{
        marginTop: "46px",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <div class="pageSub">WEST Program</div>
        {/* <div class="announcementText">Starting September 21st, 2021</div> */}
      </div>

      <div
        style={{
          marginTop: "40px",
          marginBottom: "5px",
        }}
        class="topLine"
      >
        &nbsp;
      </div>
      <div
        style={{
          marginBottom: "40px",
        }}
        class="bottomLine"
      >
        &nbsp;
      </div>

      <center className="biggerText" style={{ marginBottom: "500px" }}>
        Thank you. Someone from our team will be in touch with you shortly!
      </center>
    </div>
  </Layout>
)

export default thankyousomuch
